<template>
  <div class="mvh100 d-flex flex-column">
    <div class="pb">
      <div class="section d-flex justify-content-between">
        Персонализируйте подарок
        <v-btn icon large text class="ml-close-btn-friend" @click="goBack">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- <a href="#" @click.prevent="goBack">
          <img src="@/assets/img/close.svg" alt="" />
        </a> -->
      </div>
      <div class="ml-text-16-24-600">
        Контакты получателя
      </div>
      <div class="ml-text-14-16 mt-1 mb-2 ml-text-grey">
        По указанным данным будет отправлена персональная ссылка на получение
        сертификата.
      </div>
      <div>
        <v-form ref="form">
          <v-text-field
            ref="name"
            color="dark"
            append-icon="mdi-check"
            type="text"
            required
            :rules="rules.name"
            height="60"
            label="Имя*"
            class="ml-input ml-hide-details"
            autocomplete="off"
            :class="{ novalidate: validateName === false }"
            outlined
            v-model="form.name"
          ></v-text-field>
          <v-text-field
            color="dark"
            append-icon="mdi-check"
            type="email"
            required
            height="60"
            label="E-mail"
            class="ml-input ml-hide-details"
            autocomplete="off"
            :class="{ novalidate: validateEmail === false }"
            outlined
            v-model="form.email"
          ></v-text-field>
          <v-text-field
            ref="phone"
            autocomplete="off"
            color="dark"
            prepend-inner-icon="+7"
            append-icon="mdi-check"
            v-mask="'(###) ###-##-##'"
            required
            height="60"
            label="Телефон*"
            class="ml-input ml-input-prepend-inner"
            :class="{ novalidate: validatePhone === false }"
            outlined
            v-model="form.phone"
            @keydown.enter="applyChanges"
          >
          </v-text-field>
        </v-form>
      </div>
      <div class="mb-6">
        <div class="ml-text-16-24-600">Время отправки</div>
        <div class="ml-text-14-16 mt-1 mb-6  ml-text-grey">
          Если сертификат получателю нужно отправить сразу после оплаты, то
          время указывать не нужно.
        </div>
        <div class="ml-select-time" @click.prevent="openTimeDialog">
          <div class="ml-select-time_text flex-grow-0 pr-6">
            <div>{{ deliveryDay.day }}</div>
            <div class="ml-text-13-16 ml-text-grey3" v-if="deliveryDay.name">
              {{ deliveryDay.name }}
            </div>
          </div>
          <div class="ml-select-time_text">
            <template v-if="deliveryTime.time">
              <div>{{ deliveryTime.time }}</div>
              <div class="ml-text-13-16 ml-text-grey3">
                {{ deliveryTime.name }}
              </div>
            </template>
          </div>
          <img src="@/assets/img/arrow-right.svg" alt="" />
        </div>
      </div>
      <div>
        <div class="ml-text-16-24-600">Канал отправки</div>
        <div class="ml-text-14-16 mt-1  ml-text-grey mb-6">
          Выберите, где хотите получать информацию о состоянии заказа и
          сертификатах, которые Вы подарили
        </div>
      </div>
      <SelectChannel
        :channel="form.channel"
        @select-channel="form.channel = $event"
      />
    </div>
    <div class="controlls" ref="controlls">
      <button
        :disabled="!allowSave"
        @click.prevent="applyChanges"
        class="ml-black-btn"
        style="width: 100%"
      >
        Применить
      </button>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapActions, mapState, mapGetters } from 'vuex'
import Constants from '@/helpers/constants'
import { format, parseISO } from 'date-fns'
import orderTypes from '@/store/order/types'
import MixinObserveElement from '@/helpers/mixins/observeElement'
import SelectChannel from '@/components/SelectChannel'

export default {
  mixins: [MixinObserveElement],
  components: { SelectChannel },
  directives: { mask },
  data: () => ({
    form: {
      name: null,
      email: null,
      phone: null,
      channel: 6
    },
    basketId: null,
    routeAfterSave: null,
    routeAfterSaveDefault: 'Order',
    basketFriend: null,
    rules: {
      email: [
        v => !!v || 'Необходимо заполнить e-mail',
        v => /.+@.+/.test(v) || 'Введен некорректный e-mail'
      ],
      name: [v => !!v || 'Необходимо заполнить имя']
    }
  }),
  computed: {
    ...mapState({
      friend: state => state?.order?.friend
    }),
    ...mapGetters('basket', ['getBasketItemById']),
    allowSave() {
      // return this.validateEmail && this.validateName && this.validatePhone
      return this.validatePhone && this.validateName
    },
    deliveryTime() {
      const time = !this.basketId ? this.friend?.time : this.basketFriend?.time
      if (!time) {
        return {
          time: null,
          name: null
        }
      }
      return {
        time: time?.time,
        name: time?.text
      }
    },
    deliveryDay() {
      const { date, time } = !this.basketId ? this.friend : this.basketFriend
      if (time?.value === Constants?.TIME_TO_SEND?.NOW) {
        return {
          day: 'Сейчас',
          name: null
        }
      } else if (!date && time?.value !== Constants?.TIME_TO_SEND?.NOW) {
        return {
          day: 'Сегодня',
          name: null
        }
      }
      const dayOfWeek = new Date(date).getDay()
      return {
        day: format(parseISO(date), 'dd.MM.yyyy'),
        name: Constants?.DAY_OF_WEEK[dayOfWeek]?.name
      }
    },
    validateEmail() {
      return /.+@.+/.test(this.form.email)
    },
    validateName() {
      return this.form?.name?.length > 0
    },
    validatePhone() {
      return this.form?.phone?.length === 15
    },
    observedElement() {
      return this.$refs.controlls
    }
  },
  beforeMount() {
    this.setInitializeBefore()
  },
  mounted() {
    this.setInitialize()
  },
  beforeDestroy() {
    window?.xprops?.onHide()
  },
  methods: {
    ...mapActions('order', [orderTypes.SET_FRIEND_RECIPIENT_ACTION]),
    goBack() {
      // setTimeout(() => {
      this.$router.push({
        name: this.basketId ? this.routeAfterSave : this.routeAfterSaveDefault
      })
      // }, 200)
    },
    async saveFriend(remote = false) {
      let ownerFriendId
      if (this.basketId) {
        console.log('basket item ')
        ownerFriendId = this.getBasketItemById(this.basketId)?.friend?.friendId
      }

      await this[orderTypes.SET_FRIEND_RECIPIENT_ACTION]({
        id: this.basketId,
        name: this.form.name,
        phone: this.$helper.getClearPhone(this.form?.phone),
        email: this.form?.email,
        channel: this.form?.channel,
        remote,
        ownerFriendId
      })
    },
    applyChanges() {
      // setTimeout(() => {
      this.saveFriend(true)
      this.$router.push({
        name: this.basketId ? this.routeAfterSave : this.routeAfterSaveDefault
      })
      // }, 250)
    },
    openTimeDialog() {
      // setTimeout(() => {
      this.saveFriend(false)
      this.$router.push({
        name: 'FriendDeliveryTime',
        params: {
          routeAfterSave: this.routeAfterSave,
          dateProp: !this.basketId
            ? this.friend?.date
            : this.basketFriend?.date,
          timeProp: !this.basketId
            ? this.friend?.time
            : this.basketFriend?.time,
          basketId: this.basketId
        }
      })
      // }, 250)

      // this.$modal.show(
      //   TimeDialog,
      //   {
      //     dateProp: this.friend?.date,
      //     timeProp: this.friend?.time
      //   },
      //   {
      //     ...Config?.defaultModal,
      //     scrollable: true
      //     // width: 769,
      //     // maxWidth: 769,
      //   }
      // )
    },
    setInitializeBefore() {
      const { routeAfterSave, basketId, friend } = this.$route?.params
      this.routeAfterSave = routeAfterSave || null
      this.basketId = basketId || null
      this.basketFriend = friend || null

      const { name, phone, email, channel } = !this.basketId
        ? this.friend
        : this.basketFriend
      this.form.name = name
      this.form.email = email
      this.form.phone = phone
      this.form.channel = channel ? channel : 6

      // this.form.name = 'Юсупов Замир Алмасович'
      // this.form.email = 'qwe@qwe'
      // this.form.phone = '9224870500'

      // this.$set(this.form, 'phone', phone)
    },
    setInitialize() {
      window?.xprops?.onHide()
      this.$refs?.name?.focus()
      this.handleScroll()
    }
  }
}
</script>
